'use strict';

const SubmissionView = function ({ api, auth, bannerView, router }) {
  const self = this;

  let submissionId;
  let submissionRank;
  let submissionRibbon;
  let submissionText;
  let submissionAuthorName;
  let submissionDate;
  let submissionVoteCount;
  let submissionVote;
  let submissionShare;

  self.preinit = function (params) {
    submissionId = params.submissionId;
  };

  self.render = function () {
    return `
    <div id="submission" class="single-submission">
      <div id="submission-rank" class="submission-rank" hidden>
        <div id="submission-ribbon" class="ribbon ribbon-large">
        </div>
      </div>
      <div class="submission-text-container">
        <div class="submission-text-open-quote"></div>
        <div id="submission-text" class="submission-text"></div>
        <div class="submission-text-close-quote"></div>
      </div>
      <div class="submission-author-name-container">
        <div class="submission-author-name-dash"></div>
        <div id="submission-author-name" class="submission-author-name"></div>
      </div>
      <div id="submission-date" class="submission-date">
      </div>
      <div id="submission-vote-count" class="submission-vote-count">
      </div>
      <div class="submission-buttons">
        <button
          id="submission-vote"
          type="button"
          class="submission-vote button button-small button-no-bg icon icon-vote-small"
          aria-label="Vote For Submission"
          hidden
        >
        </button>
        <button
          id="submission-share"
          type="button"
          class="submission-share button button-small button-no-bg icon icon-share"
          aria-label="Share Submission"
        >
        </button>
      </div>
    </div>
    `;
  };

  self.init = async function () {
    submissionRank = document.getElementById('submission-rank');
    submissionRibbon = document.getElementById('submission-ribbon');
    submissionText = document.getElementById('submission-text');
    submissionAuthorName = document.getElementById('submission-author-name');
    submissionDate = document.getElementById('submission-date');
    submissionVoteCount = document.getElementById('submission-vote-count');
    submissionVote = document.getElementById('submission-vote');
    submissionShare = document.getElementById('submission-share');

    try {
      const submission = await api.request({
        method: 'GET',
        path: `/api/v1/submissions/${submissionId}`
      });

      if (!submission) {
        throw new Error('Failed to load submission');
      }

      submissionText.textContent = submission.text;
      if (submission.author_name) {
        const authorLink = document.createElement('a');
        authorLink.textContent = submission.author_name;
        authorLink.href = `/authors/${submission.author_name}`;
        submissionAuthorName.append(authorLink);
      } else {
        submissionAuthorName.textContent = 'Anonymous';
      }
      const date  = new Date(submission.timestamp);
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
      submissionDate.textContent = date.toLocaleDateString(undefined, options);
      submissionVoteCount.textContent = `Votes: ${submission.vote_count}`;
      submissionRank.hidden = (submission.rank === 0);
      submissionRibbon.textContent = `#${submission.rank}`;

      if (submission.vote_id) {
        submissionVote.classList.add('voted');
        submissionVote.setAttribute('aria-label', 'Remove Vote For Submission');
      }

      const votingDeadline = new Date();
      votingDeadline.setDate(votingDeadline.getDate() - 6);
      votingDeadline.setUTCHours(0, 0, 0, 0);
      if (new Date(submission.timestamp) > votingDeadline) {
        submissionVote.hidden = false;
        submissionVote.addEventListener('click', () => {
          onClickVote(submission, submissionVote, submissionVoteCount);
        });
      } else if (submission.vote_id) {
        submissionVote.hidden = false;
        submissionVote.classList.add('locked-in');
        submissionVote.disabled = true;
        submissionVote.setAttribute('aria-label', 'Vote Locked For Submission');
        submissionVote.setAttribute('aria-disabled', true);
      }
      if (auth.isAuthenticated() && auth.getName() === submission.author_name) {
        submissionVote.hidden = true;
      }

      submissionShare.addEventListener('click', () => onClickShare(submission));
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    }
  };

  async function onClickVote(submission, voteButton, voteCount) {
    if (!auth.isAuthenticated()) {
      window.alert('You must compose your own Mixism before you can start voting on others!');
      router.goto('/compose');
      return;
    }

    if (submission.vote_id) {
      voteButton.classList.remove('voted');

      try {
        const res = await api.request({
          method: 'DELETE',
          path: `/api/v1/submissions/${submission.id}/votes`
        });

        if (!res) {
          throw new Error('Failed to remove vote');
        }

        submission.vote_id = null;
        submission.vote_count -= 1;
        voteCount.textContent = `Votes: ${submission.vote_count}`;
        voteButton.setAttribute('aria-label', 'Vote For Submission');
      } catch (error) {
        console.error(error);
        voteButton.classList.add('voted');
      }
    } else {
      voteButton.classList.add('voted');

      try {
        const res = await api.request({
          method: 'POST',
          path: `/api/v1/submissions/${submission.id}/votes`
        });

        if (!res || !res.vote_id) {
          throw new Error('Failed to add vote');
        }

        submission.vote_id = res.vote_id;
        submission.vote_count += 1;
        voteCount.textContent = `Votes: ${submission.vote_count}`;
        voteButton.setAttribute('aria-label', 'Remove Vote For Submission');
      } catch (error) {
        console.error(error);
        voteButton.classList.remove('voted');
      }
    }
  }

  function onClickShare(submission) {
    const date  = new Date(submission.timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    const dateText = date.toLocaleDateString(undefined, options);

    navigator.clipboard.writeText(`"${submission.text}"\n- ${submission.author_name}\n${dateText}\n\n${location.href}\n\n#mixisms`);
    bannerView.addMessage({ type: 'success', text: 'Mixism copied to clipboard!' });
  }

  return self;
};

export { SubmissionView };
