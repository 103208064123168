'use strict';

const WinnersView = function ({ router, api, bannerView }) {
  const self = this;

  let winnersDate;
  let winnersNextDateButton;
  let winnerList;
  let noWinnersMessage;

  let date;
  let showNext;

  self.preinit = function (params) {
    showNext = false;

    const latestWinnerDate = new Date();
    latestWinnerDate.setDate(latestWinnerDate.getDate() - 7);
    latestWinnerDate.setUTCHours(0, 0, 0, 0);

    if (params.date) {
      date = new Date(params.date);
      if (date > latestWinnerDate) {
        router.goto('/winners');
      } else {
        showNext = (date.getTime() !== latestWinnerDate.getTime());
      }
    } else {
      date = latestWinnerDate;
    }
  };

  self.render = function () {
    return `
    <div id="winners-header">
      <a id="winners-previous-date-button" class="button button-primary" aria-label="Go To Previous Date" href="/winners/${getPreviousDate()}"><span class="icon icon-left-arrow"></span></a>
      <h1 id="winners-date"></h1>
      <a id="winners-next-date-button" class="button button-primary" aria-label="Go To Next Date" href="/winners/${getNextDate()}"><span class="icon icon-right-arrow"></span></a>
    </div>
    <ul id="winner-list" class="winner-list">
    </ul>
    <div id="no-winners" hidden>
      There were no winning submissions for this day.
    </div>
    `;
  };

  self.init = async function () {
    winnersDate = document.getElementById('winners-date');
    winnersNextDateButton = document.getElementById('winners-next-date-button');
    winnersNextDateButton.style.visibility = showNext ? 'visible' : 'hidden';
    winnerList = document.getElementById('winner-list');
    noWinnersMessage = document.getElementById('no-winners');

    const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    winnersDate.textContent = date.toLocaleDateString(undefined, options);

    try {
      const res = await api.request({
        method: 'GET',
        path: `/api/v1/winners/${date.toISOString().substr(0, 10)}`
      });

      if (!res || !res.submissions) {
        throw new Error('Failed to load winning submissions');
      }

      updateWinners(res.submissions);

      noWinnersMessage.hidden = (winnerList.childElementCount !== 0);
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    }
  };

  function updateWinners(winners) {
    while (winnerList.firstChild) {
      winnerList.removeChild(winnerList.firstChild);
    }

    const winnerListItems = document.createDocumentFragment();
    for (const winner of winners) {
      const winnerListItem = createWinnerListItem(winner);
      winnerListItems.appendChild(winnerListItem);
    }
    winnerList.appendChild(winnerListItems);
  }

  function createWinnerListItem(winner) {
    const winnerListItem = document.createElement('li');
    winnerListItem.classList.add('winner');

    const winnerTextContainer = document.createElement('div');
    winnerTextContainer.classList.add('winner-text-container');

    const winnerTextOpenQuote = document.createElement('div');
    winnerTextOpenQuote.classList.add('winner-text-open-quote');
    winnerTextContainer.appendChild(winnerTextOpenQuote);

    const winnerText = document.createElement('a');
    winnerText.classList.add('winner-text');
    winnerText.href = `/submissions/${winner.id}`;
    winnerText.textContent = winner.text;
    winnerTextContainer.appendChild(winnerText);

    const winnerTextCloseQuote = document.createElement('div');
    winnerTextCloseQuote.classList.add('winner-text-close-quote');
    winnerTextContainer.appendChild(winnerTextCloseQuote);

    winnerListItem.appendChild(winnerTextContainer);

    const winnerAuthorNameContainer = document.createElement('div');
    winnerAuthorNameContainer.classList.add('winner-author-name-container');

    const winnerAuthorNameDash = document.createElement('div');
    winnerAuthorNameDash.classList.add('winner-author-name-dash');
    winnerAuthorNameContainer.appendChild(winnerAuthorNameDash);

    const winnerAuthorName = document.createElement('div');
    winnerAuthorName.classList.add('winner-author-name');
    if (winner.author_name) {
      const authorLink = document.createElement('a');
      authorLink.textContent = winner.author_name;
      authorLink.href = `/authors/${winner.author_name}`;
      winnerAuthorName.append(authorLink);
    } else {
      winnerAuthorName.textContent = 'Anonymous';
    }
    winnerAuthorNameContainer.appendChild(winnerAuthorName);

    winnerListItem.appendChild(winnerAuthorNameContainer);

    const winnerVoteCount = document.createElement('div');
    winnerVoteCount.classList.add('winner-vote-count');
    winnerVoteCount.textContent = `Votes: ${winner.vote_count}`;
    winnerListItem.appendChild(winnerVoteCount);

    const winnerRank = document.createElement('div');
    winnerRank.classList.add('winner-rank');

    const winnerRibbon = document.createElement('div');
    winnerRibbon.classList.add('ribbon');
    winnerRibbon.textContent = `#${winner.rank}`;
    if (winner.rank === 1) {
      winnerRibbon.classList.add('ribbon-large');
    }
    winnerRank.appendChild(winnerRibbon);

    winnerListItem.appendChild(winnerRank);

    if (winner.rank === 1) {
      winnerListItem.classList.add('winner-first-place');
    }

    return winnerListItem;
  }

  function getNextDate() {
    const nextDate = new Date(date.getTime());
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate.toISOString().substr(0, 10);
  }

  function getPreviousDate() {
    const previousDate = new Date(date.getTime());
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().substr(0, 10);
  }

  return self;
};

export { WinnersView };
