'use strict';

const Errors = function () {
  const self = this;

  const errorCodeMessages = {
    required: (field) => `${field} is required`,
    alphanumunicode: (field) => `${field} must consist of alphanumeric unicode characters`,
    min: (field, param) => `${field} must be at least ${param} in length`,
    max: (field, param) => `${field} must be no more than ${param} in length`,
    has_author_chars: (field) => `${field} can only have letters, numbers, underscores, and dashes`,
    has_submission_chars: (field) => `${field} is invalid`,
    uses_todays_letters: (field) => `${field} is invalid`,
    passes_quality_threshold: (field) => `${field} did not pass quality threshold`,
  };

  const fieldMappings = {
    AuthorName: 'Pen Name',
    Name: 'Pen Name',
    SubmissionText: 'Submission',
    Text: 'Submission'
  };

  self.getErrorMessage = function ({ message, code, field, param }) {
    if (code && field) {
      if (code in errorCodeMessages) {
        return errorCodeMessages[code](fieldMappings[field] || field, param);
      }
      return `${field} failed ${code} ${param} validation`;
    } else if (message) {
      return message;
    }
    return 'Something went wrong';
  };

  return self;
};

export { Errors };
