'use strict';

const SubmissionsView = function ({ submissionListView }) {
  const self = this;

  self.views = {
    submissionList: submissionListView
  };

  self.preinit = function () {
    submissionListView.setSource('/api/v1/submissions');
  };

  self.render = function () {
    return `
    ${submissionListView.render()}
    `;
  };

  self.init = function () {
  };

  return self;
};

export { SubmissionsView };
