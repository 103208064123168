'use strict';

const BannerView = function ({ router, errors }) {
  const self = this;

  let banner;

  router.events.addEventListener('routechange', () => {
    self.clearMessages();
  });

  self.init = function () {
    banner = document.getElementById('banner');
  };

  self.addMessage = function (message) {
    const messageElement = document.createElement('div');
    messageElement.classList.add('message');
    messageElement.classList.add(message.type);
    const messageTextElement = document.createElement('div');
    messageTextElement.classList.add('message-text');
    messageTextElement.textContent = message.text;
    const dismissButton = document.createElement('button');
    dismissButton.classList.add('message-dismiss-button');
    dismissButton.classList.add('button');
    dismissButton.classList.add('button-small');
    dismissButton.classList.add('button-no-bg');
    dismissButton.setAttribute('aria-label', 'Dismiss Message');
    dismissButton.innerHTML = '&times;';
    dismissButton.addEventListener('click', onClickDismiss);

    messageElement.appendChild(messageTextElement);
    messageElement.appendChild(dismissButton);

    banner.appendChild(messageElement);
  };

  self.addMessagesForError = function (error) {
    if (error.errors) {
      for (const err of error.errors) {
        self.addMessage({ type: 'error', text: errors.getErrorMessage(err) });
      }
    } else {
      self.addMessage({ type: 'error', text: error.message });
    }
  };

  self.clearMessages = function () {
    while (banner.firstChild) {
      banner.removeChild(banner.firstChild);
    }
  };

  function onClickDismiss(event) {
    event.target.parentElement.remove();
  }

  return self;
};

export { BannerView };
