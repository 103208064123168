'use strict';

const EventEmitter = function (events) {
  const self = this;
  const eventListeners = {};

  for (const event of events) {
    eventListeners[event] = [];
  }

  self.addEventListener = function (event, callback) {
    if (!eventListeners[event]) {
      throw new Error(`${event} is not a valid event`);
    }
    eventListeners[event].push(callback);
  };

  self.dispatchEvent = function (event, data) {
    if (!eventListeners[event]) {
      throw new Error(`${event} is not a valid event`);
    }

    for (const callback of eventListeners[event]) {
      callback(data);
    }
  };

  return self;
};

export { EventEmitter };
