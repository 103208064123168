'use strict';

const SettingsView = function ({ router, auth, api, bannerView }) {
  const self = this;

  let editingAuthorName;
  let savingAuthorName;
  let showingSignature;
  let deletingAuthor;

  let linkingDevice;

  let authorNameText;
  let authorNameInput;
  let changeAuthorNameButton;
  let changeAuthorNameText;
  let saveAuthorNameButton;
  let cancelChangeAuthorNameButton;
  let signature;
  let showSignatureButton;
  let hideSignatureButton;
  let deleteAuthorButton;
  let unlinkDeviceButton;

  let nameInput;
  let signatureInput;
  let linkDeviceButton;

  self.render = function () {
    return `
      ${auth.isAuthenticated() ? renderAuthenticated() : renderUnauthenticated()}
    `;
  };

  self.init = async function () {
    auth.isAuthenticated() ? await initAuthenticated() : await initUnauthenticated();
  };

  function renderAuthenticated() {
    return `
    <div class="settings-section">
      <h2>Author Info</h2>
      <form id="settings-author-name-form" class="form">
        <div class="form-field">
          <label class="form-label" for="author-name-input">Pen Name</label>
          <span id="author-name-text" class="form-input-uneditable">...</span>
          <input
            id="author-name-input"
            class="form-input"
            autocomplete="username"
            hidden
          >
        </div>
        <div class="form-buttons">
          <button
            id="change-author-name-button"
            type="button"
            class="button button-primary button-wide"
            aria-label="Change Pen Name"
          >
            Change Pen Name
          </button>
          <p id="change-author-name-text" hidden>
            Changing your Pen Name will update it for all existing submissions
          </p>
          <button
            id="save-author-name-button"
            type="button"
            class="button button-primary button-wide"
            aria-label="Save Pen Name"
            hidden
          >
            Save
          </button>
          <button
            id="cancel-change-author-name-button"
            type="button"
            class="button button-primary-inverted button-wide"
            aria-label="Cancel Changing Pen Name"
            hidden
          >
            Cancel
          </button>
        </div>
      </form>
    </div>

    <div class="settings-section">
      <h2>Link Other Devices</h2>
      <p>
        If you would like to create Mixisms under this same Pen Name on
        another device, enter your Pen Name on the other device, press
        "Show Signature", copy the value into the "Signature" input on
        the other device, and press "Link Device".
      </p>
      <pre id="signature" hidden>${auth.getSignature()}</pre>
      <form class="form">
        <div class="form-buttons">
          <button
            id="show-signature-button"
            type="button"
            class="button button-primary button-wide"
          >
            Show Signature
          </button>
          <button
            id="hide-signature-button"
            type="button"
            class="button button-primary button-wide"
            hidden
          >
            Hide Signature
          </button>
        </div>
      </form>
    </div>

    <div class="settings-section">
      <h2>Unlink Device</h2>
      <p>
        Unlinking this device will prevent you from submitting Mixisms under your
        Pen Name on this device. It will not delete any of your Mixisms or your Author
        information, but unless you've linked a separate device, or saved your signature
        somewhere, you will be unable to submit new Mixisms under your Pen Name anymore.
        This action cannot be undone! You will be unable to reclaim your Mixisms!
      </p>
      <form class="form">
        <div class="form-buttons">
          <button
            id="unlink-device-button"
            type="button"
            class="button button-danger button-wide"
            aria-label="Unlink Device"
          >
            Unlink Device
          </button>
        </div>
      </form>
    </div>

    <div class="settings-section">
      <h2>Remove Author</h2>
      <p>
        Deleting your Author will not remove the Mixisms you've composed,
        but will remove your Pen Name from the submissions.
        This action cannot be undone! You will be unable to reclaim your Mixisms!
      </p>
      <form class="form">
        <div class="form-buttons">
          <button
            id="delete-author-button"
            type="button"
            class="button button-danger button-wide"
            aria-label="Delete Author"
          >
            Delete Author
          </button>
        </div>
      </form>
    </div>
    `;
  }

  function renderUnauthenticated() {
    return `
    <div class="settings-section">
      <h2>Link This Device</h2>
      <p>
        If you would like to use this device to create Mixisms under an existing
        Pen Name, enter your Pen Name on this device, press "Show Signature" on the
        other device, copy the value into the "Signature" input, and press "Link Device".
      </p>
      <form id="settings-link-form" class="form">
        <div class="form-field">
          <label class="form-label" for="name-input">Pen Name</label>
          <input
            id="name-input"
            class="form-input"
          >
        </div>
        <div class="form-field">
          <label class="form-label" for="signature-input">Signature</label>
          <input
            id="signature-input"
            class="form-input"
          >
        </div>
        <div class="form-buttons">
          <button
            id="link-device-button"
            type="button"
            class="button button-primary button-wide"
          >
            Link Device
          </button>
        </div>
      </form>
    </div>
    `;
  }

  async function initAuthenticated() {
    authorNameText = document.getElementById('author-name-text');
    authorNameInput = document.getElementById('author-name-input');
    changeAuthorNameButton = document.getElementById('change-author-name-button');
    changeAuthorNameButton.addEventListener('click', onClickChangeAuthorName);
    changeAuthorNameText = document.getElementById('change-author-name-text');
    saveAuthorNameButton = document.getElementById('save-author-name-button');
    saveAuthorNameButton.addEventListener('click', onClickSaveAuthorName);
    cancelChangeAuthorNameButton = document.getElementById('cancel-change-author-name-button');
    cancelChangeAuthorNameButton.addEventListener('click', onClickCancelChangeAuthorName);
    signature = document.getElementById('signature');
    showSignatureButton = document.getElementById('show-signature-button');
    showSignatureButton.addEventListener('click', onClickShowSignatureButton);
    hideSignatureButton = document.getElementById('hide-signature-button');
    hideSignatureButton.addEventListener('click', onClickHideSignatureButton);
    deleteAuthorButton = document.getElementById('delete-author-button');
    deleteAuthorButton.addEventListener('click', onClickDeleteAuthor);
    unlinkDeviceButton = document.getElementById('unlink-device-button');
    unlinkDeviceButton.addEventListener('click', onClickUnlinkDevice);

    editingAuthorName = false;
    savingAuthorName = false;
    showingSignature = false;
    deletingAuthor = false;

    updateAuthenticated();
  }

  async function initUnauthenticated() {
    nameInput = document.getElementById('name-input');
    signatureInput = document.getElementById('signature-input');
    linkDeviceButton = document.getElementById('link-device-button');
    linkDeviceButton.addEventListener('click', onClickLinkDevice);

    linkingDevice = false;
  }

  function updateAuthenticated() {
    changeAuthorNameButton.hidden = editingAuthorName;
    changeAuthorNameText.hidden = !editingAuthorName;
    saveAuthorNameButton.hidden = !editingAuthorName;
    cancelChangeAuthorNameButton.hidden = !editingAuthorName;
    authorNameText.hidden = editingAuthorName;
    authorNameInput.hidden = !editingAuthorName;

    if (editingAuthorName) {
      authorNameInput.value = auth.getName();
    } else {
      authorNameText.textContent = auth.getName();
    }

    showSignatureButton.hidden = showingSignature;
    hideSignatureButton.hidden = !showingSignature;
    signature.hidden = !showingSignature;
  }

  function onClickChangeAuthorName() {
    bannerView.clearMessages();
    editingAuthorName = true;
    updateAuthenticated();
  }

  async function onClickSaveAuthorName() {
    if (savingAuthorName) {
      return;
    }
    savingAuthorName = true;

    bannerView.clearMessages();

    try {
      const name = authorNameInput.value.trim();

      if (name.length === 0) {
        authorNameInput.focus();
        throw new Error('Pen name is required');
      }

      if (name === auth.getName()) {
        return;
      }

      const author = await api.request({
        method: 'PUT',
        path: '/api/v1/authors/me',
        data: { name: name }
      });
      if (!author) {
        throw new Error('Author update failed');
      }

      auth.setName(author.name);

      bannerView.addMessage({ type: 'success', text: 'Author updated!' });
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    } finally {
      savingAuthorName = false;
      editingAuthorName = false;
      updateAuthenticated();
    }
  }

  function onClickCancelChangeAuthorName() {
    bannerView.clearMessages();
    editingAuthorName = false;
    updateAuthenticated();
  }

  function onClickShowSignatureButton() {
    showingSignature = true;
    updateAuthenticated();
  }

  function onClickHideSignatureButton() {
    showingSignature = false;
    updateAuthenticated();
  }

  async function onClickLinkDevice() {
    if (linkingDevice) {
      return;
    }
    linkingDevice = true;

    bannerView.clearMessages();

    try {
      const name = nameInput.value.trim();
      const signature = signatureInput.value.trim();

      if (name.length === 0) {
        nameInput.focus();
        throw new Error('Pen Name is required');
      }
      if (signature.length === 0) {
        signatureInput.focus();
        throw new Error('Signature is required');
      }

      const author = await api.request({
        method: 'PUT',
        path: '/api/v1/authors/me',
        headers: { 'Authorization': `Bearer ${name}:${signature}` },
        data: {}
      });
      if (!author) {
        throw new Error('Failed to link device');
      }

      auth.setName(author.name);
      auth.setSignature(signature);

      router.goto('/');

      bannerView.addMessage({ type: 'success', text: 'Successfully linked device!' });
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    } finally {
      nameInput.value = '';
      signatureInput.value = '';
      linkingDevice = false;
    }
  }

  async function onClickDeleteAuthor() {
    if (deletingAuthor) {
      return;
    }
    deletingAuthor = true;

    bannerView.clearMessages();

    const confirmName = window.prompt(
      'You are about to delete your author permanently!\n' +
      'You cannot undo this operation!\n\n' +
      `Please type your Pen Name "${auth.getName()}" to confirm:`
    );
    if (!confirmName || confirmName !== auth.getName()) {
      deletingAuthor = false;
      return;
    }

    try {
      const res = await api.request({
        method: 'DELETE',
        path: '/api/v1/authors/me',
        data: { name: confirmName }
      });
      if (!res) {
        throw new Error('Author delete failed');
      }

      router.goto('/');

      bannerView.addMessage({ type: 'success', text: 'Author deleted!' });
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    } finally {
      deletingAuthor = false;
      auth.clear();
    }
  }

  async function onClickUnlinkDevice() {
    const confirmName = window.prompt(
      'You are about to unlink this device which will prevent you from ' +
      'submitting Mixisms under your Pen Name on this device!\n' +
      'You cannot undo this operation!\n\n' +
      `Please type your Pen Name "${auth.getName()}" to confirm:`
    );
    if (!confirmName || confirmName !== auth.getName()) {
      return;
    }

    localStorage.clear();
    auth.clear();

    router.goto('/');

    bannerView.addMessage({ type: 'success', text: 'Successfully unlinked device!' });
  }

  return self;
};

export { SettingsView };
