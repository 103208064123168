'use strict';

const NewsView = function ({ news }) {
  const self = this;

  let noArticles;
  let articles;

  self.render = function () {
    return `
    <div id="no-articles" hidden>No news is good news!</div>
    <div id="articles"></div>
    `;
  };

  self.init = function () {
    noArticles = document.getElementById('no-articles');
    articles = document.getElementById('articles');

    noArticles.hidden = (Object.keys(news.getArticles()).length > 0);

    updateArticles();

    news.markRead();
  };

  function updateArticles() {
    for (const newsArticle of news.getArticles()) {
      const article = document.createElement('div');
      article.classList.add('article');
      const articleTitle = document.createElement('h2');
      articleTitle.classList.add('article-title');
      articleTitle.textContent = newsArticle.title;
      const articleDate = document.createElement('div');
      articleDate.classList.add('article-date');
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
      articleDate.textContent = newsArticle.date.toLocaleDateString(undefined, options);
      const articleText = document.createElement('div');
      articleText.classList.add('article-text');
      articleText.innerHTML = newsArticle.html;

      article.appendChild(articleTitle);
      article.appendChild(articleDate);
      article.appendChild(articleText);

      articles.appendChild(article);
    }
  }

  return self;
};

export { NewsView };
