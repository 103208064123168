'use strict';

import { EventEmitter } from './event';

// Add newest articles at the beginning
const articles = [
  {
    title: 'Mixisms for everyone!',
    date: new Date(2022, 4, 12),
    html: `
      <p>
        Well! It sure has been a while! But I have some exciting updates!
      </p>
      <p>
        Something that has always bothered me about Mixisms is the need for
        users to have a login. That's just another email and password that
        people will struggle to remember, and it was really just a barrier
        preventing people from playing in the first place.
      </p>
      <p>
        However! Now you'll notice you no longer need to login to use Mixisms!
        Now anyone and everyone can join the fun!
      </p>
      <p>
        A few other updates are also worth noting:
        <ol>
          <li>Dark/Light modes (based on browser settings)</li>
          <li>Linking devices for creating Mixisms under the same Author</li>
          <li>Letter frequencies now better match the English language</li>
          <li>More intelligent punctuation placement</li>
          <li>And a whole bunch of backend server stuff you probably don't care about!</li>
        </ol>
      </p>
      <p>
        I'm looking forward to seeing the Mixisms everyone comes up with!
      </p>
    `
  },
  {
    title: 'Don\'t you just love deadlines?',
    date: new Date(2020, 0, 26),
    html: `
      <p>
        Okay, maybe not. But, I'm sure you'll agree it's nice to know when one
        is coming up!
      </p>
      <p>
        Long gone is the day when you are composing a mixism, and the day ticks
        over and your perfectly poetic creation is lost just because you didn't
        realize how much time was left!
      </p>
      <p>
        Well now there is a deadline visualization that will tell you how long you
        have before the current day's letters expire. Note that Mixisms will
        tick over at midnight in the UTC timezone. So this would be at
        7pm Eastern, 5pm Mountain, 4pm Pacific, etc. (This will also depend on
        when daylight savings is in your area).
      </p>
      <p>
        Hopefully this leads to a little bit less frustration when composing!
      </p>
    `
  },
  {
    title: 'Some news is good news!',
    date: new Date(2020, 0, 21),
    html: `
    <p>
      As you may have noticed, Mixisms now has a brand spanking new News page!
    </p>
    <p>
      Now you'll be the first to know about any new features as they come in,
      along with any other newsworthy topics concerning Mixisms.
    </p>
    <p>
      Keep on mixing things up!
    </p>
  `
  },
];

const News = function () {
  const self = this;

  let newNews = false;

  self.events = new EventEmitter(['newnews']);

  self.getArticles = function () {
    return articles;
  };

  self.hasNewNews = function () {
    return newNews;
  };

  self.markRead = function () {
    localStorage.setItem('last_read_news_date', Date.now());
    update();
  };

  function update() {
    const prevNewNews = newNews;
    const lastReadNewsDate = localStorage.getItem('last_read_news_date') || new Date(0);

    newNews = articles.length > 0 && articles[0].date > lastReadNewsDate;

    if (prevNewNews !== newNews) {
      self.events.dispatchEvent('newnews', newNews);
    }
  }

  update();

  return self;
};

export { News };
