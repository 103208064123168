'use strict';

const ComposeView = function ({ router, api, auth, bannerView, composeBoardView }) {
  const self = this;

  let shuffleButton;
  let submitButton;
  let composeDate;
  let composeTimerText;
  let composeTimerBar;
  let authorNameInput;
  let date;
  let letters;
  let timer;
  let submitting;

  self.views = {
    composeBoard: composeBoardView
  };

  self.render = function () {
    return `
    <div id="compose-header">
      <h1 id="compose-date"></h1>
      <div class="compose-buttons">
        <button
          id="compose-shuffle-button"
          type="button"
          class="button button-primary"
          aria-label="Shuffle Letters"
        >
          <span class="icon icon-reset"></span>
        </button>
        <button
          id="compose-submit-button"
          type="button"
          class="button button-primary"
          aria-label="Submit Mixism"
        >
          Submit
        </button>
      </div>
    </div>
    <div id="compose-timer">
      <div id="compose-timer-text">
      </div>
      <div id="compose-timer-bar">
      </div>
    </div>
    ${composeBoardView.render()}
    <form id="compose-author-name-form" class='form'>
      <div class="form-field">
        <label class="form-label" for="author-name">Pen Name</label>
        <input id="author-name" class="form-input" type="text"/>
      </div>
    </form>
    `;
  };

  self.init = async function () {
    shuffleButton = document.getElementById('compose-shuffle-button');
    shuffleButton.addEventListener('click', onClickShuffle);
    submitButton = document.getElementById('compose-submit-button');
    submitButton.addEventListener('click', onClickSubmit);
    composeDate = document.getElementById('compose-date');
    composeTimerText = document.getElementById('compose-timer-text');
    composeTimerBar = document.getElementById('compose-timer-bar');
    authorNameInput = document.getElementById('author-name');

    submitting = false;

    letters = '';
    try {
      const res = await api.request({
        method: 'GET',
        path: '/api/v1/letters'
      });

      if (!res || !res.letters) {
        throw new Error('Failed to load letters');
      }

      letters = res.letters;
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    }

    composeBoardView.setLetters(letters);

    date = new Date();
    const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    composeDate.textContent = date.toLocaleDateString(undefined, options);

    authorNameInput.value = auth.getName();

    tickTimer();
  };

  self.destroy = function () {
    clearTimeout(timer);
  };

  function tickTimer() {
    const now = new Date();
    const nextDay = new Date(now);
    nextDay.setUTCHours(24, 0, 0, 0);
    const secondsToNextDay = Math.floor((nextDay - now) / 1000);

    if (date.getUTCDate() !== now.getUTCDate() || secondsToNextDay < 0) {
      if (!window.alert('The time has expired for these letters!')) {
        window.location.reload();
      }
    }

    let timerText = '';
    if (secondsToNextDay > 60 * 60) {
      timerText = `${Math.floor(secondsToNextDay / (60 * 60))}h ${Math.floor((secondsToNextDay % (60 * 60)) / 60)}m remaining`;
    } else if (secondsToNextDay > 60) {
      timerText = `${Math.floor((secondsToNextDay % (60 * 60)) / 60)}m remaining`;
    } else {
      timerText = `${secondsToNextDay}s remaining`;
    }

    composeTimerText.textContent = timerText;
    composeTimerBar.style.width = `${(secondsToNextDay / (24 * 60 * 60)) * 100}%`;
    if (secondsToNextDay < 60 * 60) {
      composeTimerText.style.color = 'var(--color-danger)';
      composeTimerBar.style.background = 'var(--color-danger)';
    } else {
      composeTimerText.style.color = 'var(--color-secondary)';
      composeTimerBar.style.background = 'var(--color-primary)';
    }

    timer = setTimeout(() => {
      tickTimer();
    }, 1000);
  }

  function onClickShuffle() {
    composeBoardView.clearSavedState();
    composeBoardView.setLetters(letters);
  }

  async function onClickSubmit() {
    if (submitting) {
      return;
    }
    submitting = true;

    bannerView.clearMessages();

    try {
      const message = composeBoardView.getMessage();
      const name = authorNameInput.value.trim();

      if (name.length === 0) {
        authorNameInput.focus();
        throw new Error('Pen name is required');
      }

      if (auth.isAuthenticated()) {
        const submission = await api.request({
          method: 'POST',
          path: '/api/v1/submissions',
          data: { text: message }
        });
        if (!submission) {
          throw new Error('Submission failed');
        }

        if (name !== auth.getName()) {
          const author = await api.request({
            method: 'PUT',
            path: '/api/v1/authors/me',
            data: { name: name }
          });
          if (!author) {
            throw new Error('Author name update failed');
          }
          auth.setName(name);
        }
      } else {
        const author = await api.request({
          method: 'POST',
          path: '/api/v1/authors',
          data: {
            name: name,
            submission_text: message
          }
        });
        if (!author) {
          throw new Error('Author creation failed');
        }
        auth.setSignature(author.signature);
        auth.setName(name);
      }

      composeBoardView.clearSavedState();

      router.goto('/submissions');
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    } finally {
      submitting = false;
    }
  }

  return self;
};

export { ComposeView };
