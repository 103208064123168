'use strict';

import { EventEmitter } from './event';

const Router = function () {
  const self = this;

  let sections = {};
  let routes = {};
  let noRoute = {};
  let routeData = {};
  let currentRoute;
  let prevViewOverrides = {};

  self.events = new EventEmitter(['routechange']);

  self.init = async function (opts) {
    sections = typeof opts.sections === 'undefined' ? {} : opts.sections;
    routes = typeof opts.routes === 'undefined' ? {} : opts.routes;
    noRoute = typeof opts.noRoute === 'undefined' ? {} : opts.noRoute;

    await loadDefaultViews();
    await loadRoute(window.location.pathname);

    window.addEventListener('popstate', onPopState);
    window.addEventListener(document.ontouchstart ? 'touchstart' : 'click', onClick);
  };

  self.goto = function (url, data) {
    console.debug(`Going to ${url}`);
    history.pushState({}, '', url);
    loadRoute(window.location.pathname, data);
  };

  self.getData = function () {
    return routeData;
  };

  async function loadDefaultViews() {
    for (const section in sections) {
      const view = sections[section].view;
      console.debug('preinit', section, view);
      await preinitView(view);
    }

    for (const section in sections) {
      const view = sections[section].view;
      console.debug('render', section, view);
      sections[section].root.innerHTML = view.render ? view.render() : '';
    }

    for (const section in sections) {
      const view = sections[section].view;
      console.debug('init', section, view);
      await initView(view);
    }
  }

  async function loadRoute(url, data) {
    console.debug(`Loading ${url}`);

    if (currentRoute === url) {
      return;
    }
    currentRoute = url;

    routeData = typeof data === 'undefined' ? {} : data;

    self.events.dispatchEvent('routechange');

    window.scrollTo(0, 0);

    let viewOverrides = {};
    let params;
    const urlSegments = getPathSegments(url);
    for (const route in routes) {
      const routeSegments = getPathSegments(route);
      params = {};

      if (routeSegments.length === urlSegments.length) {
        let matched = true;

        for (let i = 0; i < urlSegments.length; i++) {
          if (routeSegments[i][0] === ':') {
            params[routeSegments[i].slice(1)] = sanitizeParam(urlSegments[i]);
          } else {
            if (routeSegments[i] !== urlSegments[i]) {
              matched = false;
            }
          }
        }

        if (matched) {
          viewOverrides = routes[route];
          break;
        }
      }
    }

    if (Object.keys(viewOverrides).length === 0) {
      viewOverrides = noRoute;
    }

    for (const section in viewOverrides) {
      const view = prevViewOverrides[section];
      if (view) {
        console.debug('destroy', section, view);
        await destroyView(view, params);
      }
    }

    for (const section in viewOverrides) {
      const view = viewOverrides[section];
      console.debug('preinit', section, view);
      await preinitView(view, params);
    }

    if (currentRoute !== url) {
      return;
    }

    for (const section in viewOverrides) {
      const view = viewOverrides[section];
      console.debug('render', section, view);
      sections[section].root.innerHTML = view.render ? view.render(params) : '';
    }

    for (const section in viewOverrides) {
      const view = viewOverrides[section];
      console.debug('init', section, view);
      await initView(view, params);
    }

    prevViewOverrides = viewOverrides;
  }

  async function preinitView(view, params) {
    if (view.views) {
      for (const childView in view.views) {
        console.debug('preinit child view', childView);
        await preinitView(view.views[childView], params);
      }
    }
    if (view.preinit) {
      console.debug('preinit', view);
      await view.preinit(params);
    }
  }

  async function initView(view, params) {
    if (view.views) {
      for (const childView in view.views) {
        console.debug('init child view', childView);
        await initView(view.views[childView], params);
      }
    }
    if (view.init) {
      console.debug('init', view);
      await view.init(params);
    }
  }

  async function destroyView(view, params) {
    if (view.views) {
      for (const childView in view.views) {
        console.debug('destroy child view', childView);
        await destroyView(view.views[childView], params);
      }
    }
    if (view.destroy) {
      console.debug('destroy', view);
      await view.destroy(params);
    }
  }

  function onClick (event) {
    event = event || window.event;

    const button = event.which || event.button;
    if (button !== 1) {
      return;
    }
    if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
      return;
    }
    if (event.defaultPrevented) {
      return;
    }

    const link = getLinkElement(event);
    if (!link || !onSameOrigin(link) || (onSamePath(link) && usesHash(link))) {
      return;
    }

    event.preventDefault();

    self.goto(buildPath(link));
  }

  function onPopState() {
    loadRoute(window.location.pathname);
  }

  function getPathSegments(path) {
    const segments = path.split('/');
    if (segments[segments.length - 1] === '') {
      segments.pop();
    }
    return segments;
  }

  function sanitizeParam(param) {
    return param.replace(/[^-A-Za-z0-9_]/g, '');
  }

  function getLinkElement(event) {
    let element = event.target;
    let eventPath = event.composedPath ? event.composedPath() : null;
    if (eventPath) {
      for (const node of eventPath) {
        if (node.nodeName && node.nodeName.toUpperCase() === 'A' && node.href) {
          element = node;
          break;
        }
      }
    }
    while (element && element.nodeName.toUpperCase() !== 'A') {
      element = element.parentNode;
    }
    if (!element || element.nodeName.toUpperCase() !== 'A' || !element.href) {
      element = null;
    }
    return element;
  }

  function onSameOrigin(link) {
    const url = new URL(link.href, window.location.toString());
    return (
      window.location.protocol === url.protocol &&
      window.location.hostname === url.hostname &&
      window.location.port === url.port
    );
  }

  function onSamePath(link) {
    return (
      window.location.pathname === link.pathname &&
      window.location.search === link.search
    );
  }

  function usesHash(link) {
    return (link.href === '#' || link.hash);
  }

  function buildPath(link) {
    return `${link.pathname[0] === '/' ? '' : '/'}${link.pathname}${link.search}${link.hash || ''}`;
  }

  return self;
};

export { Router };
