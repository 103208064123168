'use strict';

const SubmissionListView = function ({ router, auth, api, bannerView }) {
  const self = this;

  let submissionList;
  let submissionListStartLoading;
  let submissionListEndLoading;
  let noSubmissionsMessage;

  let deletingSubmission;

  let source;
  let loading;
  let more;
  let first;
  let last;
  let prevScroll;

  router.events.addEventListener('routechange', () => {
    source = '/api/v1/submissions';
  });

  self.render = function () {
    return `
    <div id="submission-list-start-loading" class="list-loading">
      Checking for new mixisms...
    </div>
    <ul id="submission-list" class="submission-list">
    </ul>
    <div class="list-loading-fixed-height">
      <div id="submission-list-end-loading" class="list-loading">
        Getting older mixisms...
      </div>
    </div>
    <div id="no-submissions" hidden>
      There are no submissions.
    </div>
    `;
  };

  self.init = async function () {
    submissionList = document.getElementById('submission-list');
    submissionListStartLoading = document.getElementById('submission-list-start-loading');
    submissionListEndLoading = document.getElementById('submission-list-end-loading');
    noSubmissionsMessage = document.getElementById('no-submissions');

    deletingSubmission = false;

    source = source ? source : '/api/v1/submissions';
    loading = false;
    more = true;
    first = new Date(1900);
    last = new Date();
    prevScroll = 0;

    window.addEventListener('scroll', onScroll);

    await fetchSubmissions();
  };

  self.destroy = function () {
    window.removeEventListener('scroll', onScroll);
  };

  self.setSource = function (newSource) {
    source = newSource;
  };

  async function fetchSubmissions({ fetchNew } = { fetchNew: false }) {
    bannerView.clearMessages();

    noSubmissionsMessage.hidden = true;

    if (loading) {
      return;
    }
    loading = true;

    if (fetchNew) {
      submissionListStartLoading.classList.add('list-loading-open');
    } else {
      submissionListEndLoading.classList.add('list-loading-open');
    }
    setLoadingTimer();

    try {
      const res = await api.request({
        method: 'GET',
        path: source,
        params: { latest: fetchNew ? (new Date()).toISOString() : last.toISOString() }
      });

      if (!res || !res.submissions) {
        throw new Error('Failed to load submissions');
      }

      const earliest = new Date(res.earliest);
      const latest = new Date(res.latest);

      if (fetchNew) {
        if (latest.getTime() !== first.getTime()) {
          while (submissionList.firstChild) {
            submissionList.removeChild(submissionList.firstChild);
          }
          appendSubmissions(res.submissions);
        }
      } else {
        appendSubmissions(res.submissions);
      }

      noSubmissionsMessage.hidden = (submissionList.childElementCount !== 0);

      more = res.submissions.length > 0;

      if (more) {
        if (!first || latest > first) {
          first = latest;
        }
        if (!last || earliest < last) {
          last = earliest;
        }
      }
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
      more = false;
    }

    loading = false;
  }

  function appendSubmissions(submissions) {
    const submissionListItems = document.createDocumentFragment();
    for (const submission of submissions) {
      const submissionListItem = createSubmissionListItem(submission);
      submissionListItems.appendChild(submissionListItem);
    }
    submissionList.appendChild(submissionListItems);
  }

  function createSubmissionListItem(submission) {
    const submissionListItem = document.createElement('li');
    submissionListItem.classList.add('submission');

    const submissionTextContainer = document.createElement('div');
    submissionTextContainer.classList.add('submission-text-container');

    const submissionTextOpenQuote = document.createElement('div');
    submissionTextOpenQuote.classList.add('submission-text-open-quote');
    submissionTextContainer.appendChild(submissionTextOpenQuote);

    const submissionText = document.createElement('a');
    submissionText.classList.add('submission-text');
    submissionText.href = `/submissions/${submission.id}`;
    submissionText.textContent = submission.text;
    submissionTextContainer.appendChild(submissionText);

    const submissionTextCloseQuote = document.createElement('div');
    submissionTextCloseQuote.classList.add('submission-text-close-quote');
    submissionTextContainer.appendChild(submissionTextCloseQuote);

    submissionListItem.appendChild(submissionTextContainer);

    const submissionAuthorNameContainer = document.createElement('div');
    submissionAuthorNameContainer.classList.add('submission-author-name-container');

    const submissionAuthorNameDash = document.createElement('div');
    submissionAuthorNameDash.classList.add('submission-author-name-dash');
    submissionAuthorNameContainer.appendChild(submissionAuthorNameDash);

    const submissionAuthorName = document.createElement('div');
    submissionAuthorName.classList.add('submission-author-name');
    if (submission.author_name) {
      const authorLink = document.createElement('a');
      authorLink.textContent = submission.author_name;
      authorLink.href = `/authors/${submission.author_name}`;
      submissionAuthorName.append(authorLink);
    } else {
      submissionAuthorName.textContent = 'Anonymous';
    }
    submissionAuthorNameContainer.appendChild(submissionAuthorName);

    submissionListItem.appendChild(submissionAuthorNameContainer);

    const submissionVoteCount = document.createElement('div');
    submissionVoteCount.classList.add('submission-vote-count');
    submissionVoteCount.textContent = `Votes: ${submission.vote_count}`;
    submissionListItem.appendChild(submissionVoteCount);

    if (submission.rank > 0) {
      const submissionRank = document.createElement('div');
      submissionRank.classList.add('submission-rank');

      const submissionRibbon = document.createElement('div');
      submissionRibbon.classList.add('ribbon');
      submissionRibbon.textContent = `#${submission.rank}`;
      submissionRank.appendChild(submissionRibbon);

      submissionListItem.appendChild(submissionRank);
    }

    const submissionDate = document.createElement('div');
    submissionDate.classList.add('submission-date');
    const date  = new Date(submission.timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    submissionDate.textContent = date.toLocaleDateString(undefined, options);
    submissionListItem.appendChild(submissionDate);

    if (auth.isAuthenticated() && auth.getName() === submission.author_name) {
      const submissionDelete = document.createElement('button');
      submissionDelete.classList.add('submission-delete');
      submissionDelete.classList.add('button');
      submissionDelete.classList.add('button-small');
      submissionDelete.classList.add('button-no-bg');
      submissionDelete.classList.add('icon');
      submissionDelete.classList.add('icon-delete');
      submissionDelete.setAttribute('aria-label', 'Delete Submission');
      submissionDelete.addEventListener('click', () => {
        onClickDeleteSubmission(submission, submissionListItem);
      });
      submissionListItem.appendChild(submissionDelete);
    } else {
      const submissionVote = document.createElement('button');
      submissionVote.classList.add('submission-vote');
      submissionVote.classList.add('button');
      submissionVote.classList.add('button-small');
      submissionVote.classList.add('button-no-bg');
      submissionVote.classList.add('icon');
      submissionVote.classList.add('icon-vote-small');
      submissionVote.setAttribute('aria-label', 'Vote For Submission');

      if (submission.vote_id) {
        submissionVote.classList.add('voted');
        submissionVote.setAttribute('aria-label', 'Remove Vote For Submission');
      }

      const votingDeadline = new Date();
      votingDeadline.setDate(votingDeadline.getDate() - 6);
      votingDeadline.setUTCHours(0, 0, 0, 0);
      if (new Date(submission.timestamp) > votingDeadline) {
        submissionVote.addEventListener('click', () => {
          onClickVote(submission, submissionVote, submissionVoteCount);
        });
        submissionListItem.appendChild(submissionVote);
      } else if (submission.vote_id) {
        submissionVote.classList.add('locked-in');
        submissionVote.disabled = true;
        submissionVote.setAttribute('aria-label', 'Vote Locked For Submission');
        submissionVote.setAttribute('aria-disabled', true);
        submissionListItem.appendChild(submissionVote);
      }
    }

    return submissionListItem;
  }

  async function onClickDeleteSubmission(submission, submissionListItem) {
    if (deletingSubmission) {
      return;
    }
    deletingSubmission = true;

    bannerView.clearMessages();

    const confirmDelete = window.confirm(
      'Are you sure you want to delete your submission?\n' +
      'You cannot undo this operation!\n\n' +
      'Please note that winning submissions will still be seen by others, but will have your Pen Name removed.'
    );
    if (!confirmDelete) {
      deletingSubmission = false;
      return;
    }

    try {
      const res = await api.request({
        method: 'DELETE',
        path: `/api/v1/submissions/${submission.id}`
      });

      if (!res) {
        throw new Error('Failed to delete submission');
      }

      submissionList.removeChild(submissionListItem);

      bannerView.addMessage({ type: 'success', text: 'Successfully deleted submission' });
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    } finally {
      deletingSubmission = false;
    }
  }

  async function onClickVote(submission, voteButton, voteCount) {
    if (!auth.isAuthenticated()) {
      window.alert('You must compose your own Mixism before you can start voting on others!');
      router.goto('/compose');
      return;
    }

    if (submission.vote_id) {
      voteButton.classList.remove('voted');

      try {
        const res = await api.request({
          method: 'DELETE',
          path: `/api/v1/submissions/${submission.id}/votes`
        });

        if (!res) {
          throw new Error('Failed to remove vote');
        }

        submission.vote_id = null;
        submission.vote_count -= 1;
        voteCount.textContent = `Votes: ${submission.vote_count}`;
        voteButton.setAttribute('aria-label', 'Vote For Submission');
      } catch (error) {
        console.error(error);
        voteButton.classList.add('voted');
      }
    } else {
      voteButton.classList.add('voted');

      try {
        const res = await api.request({
          method: 'POST',
          path: `/api/v1/submissions/${submission.id}/votes`
        });

        if (!res || !res.vote_id) {
          throw new Error('Failed to add vote');
        }

        submission.vote_id = res.vote_id;
        submission.vote_count += 1;
        voteCount.textContent = `Votes: ${submission.vote_count}`;
        voteButton.setAttribute('aria-label', 'Remove Vote For Submission');
      } catch (error) {
        console.error(error);
        voteButton.classList.remove('voted');
      }
    }
  }

  async function onScroll() {
    const scrollTop = document.scrollingElement ? document.scrollingElement.scrollTop : document.documentElement.scrollTop;
    const height = document.documentElement.offsetHeight;
    const scroll = Math.round(scrollTop + window.innerHeight);
    const startScroll = (scroll <= window.innerHeight);
    const endScroll = ((more && scroll > height - 600) || (!more && scroll >= height));

    if (scroll !== prevScroll) {
      prevScroll = scroll;

      if (startScroll) {
        await fetchSubmissions({ fetchNew: true });
      } else if (endScroll) {
        await fetchSubmissions();
      }
    }
  }

  function setLoadingTimer() {
    setTimeout(() => {
      if (loading) {
        setLoadingTimer();
        return;
      }

      submissionListStartLoading.classList.remove('list-loading-open');
      submissionListEndLoading.classList.remove('list-loading-open');
    }, 1000);
  }

  return self;
};

export { SubmissionListView };
