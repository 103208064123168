'use strict';

const HeaderView = function ({ auth, install, news }) {
  const self = this;

  let authorMenuButton;
  let newNewsHeaderIcon;
  let newNewsMenuIcon;
  let authorMenu;
  let myMixismsButton;
  let installAppButton;

  auth.events.addEventListener('namechange', update);
  auth.events.addEventListener('signaturechange', update);

  install.events.addEventListener('readytoinstall', () => {
    if (installAppButton) {
      installAppButton.hidden = false;
    }
  });

  news.events.addEventListener('newnews', (newNews) => {
    newNewsHeaderIcon.hidden = !newNews;
    newNewsMenuIcon.hidden = !newNews;
  });

  self.render = function () {
    return `
    <h1><a href="/" aria-label="Mixisms Home"><span class="logo-m">M</span>ixisms</a></h1>

    <div class="header-buttons">
      <a id="compose-button" class="button button-primary" aria-label="Compose" href="/compose">
        <span class="icon icon-compose"></span>
        <span class="header-button-text">Compose</span>
      </a>
      <a id="vote-button" class="button button-primary" aria-label="Vote" href="/submissions">
        <span class="icon icon-vote"></span>
        <span class="header-button-text">Vote</span>
      </a>
      <a id="winners-button" class="button button-primary" aria-label="Winners" href="/winners">
        <span class="icon icon-winners"></span>
        <span class="header-button-text">Winners</span>
      </a>
      <button
        id="author-menu-button"
        type="button"
        class="button button-primary"
        aria-label="Toggle Menu"
      >
        <span class="icon icon-menu"></span>
      </button>
      <div id="new-news-header-icon" class="new-news-icon" hidden>!</div>
      <div id="author-menu" hidden>
        <a id="my-mixisms-button" class="author-menu-item button button-primary button-no-bg" href="/authors/${auth.getName()}/submissions">My Mixisms</a>
        <a id="settings-button" class="author-menu-item button button-primary button-no-bg" href="/settings">Settings</a>
        <a id="news-button" class="author-menu-item button button-primary button-no-bg" href="/news">News<div id="new-news-menu-icon" class="new-news-icon" hidden>!</div></a>
        <button
          id="install-button"
          type="button"
          class="author-menu-item button button-primary button-no-bg"
          hidden
        >
          Install App
        </button>
    </div>
    `;
  };

  self.init = function () {
    authorMenuButton = document.getElementById('author-menu-button');
    authorMenuButton.addEventListener('click', onClickAuthorMenu);
    newNewsHeaderIcon = document.getElementById('new-news-header-icon');
    newNewsMenuIcon = document.getElementById('new-news-menu-icon');
    authorMenu = document.getElementById('author-menu');
    myMixismsButton = document.getElementById('my-mixisms-button');
    installAppButton = document.getElementById('install-button');
    installAppButton.addEventListener('click', onClickInstallApp);

    window.addEventListener('click', () => {
      authorMenu.hidden = true;
    });

    if (install.canInstallApp()) {
      installAppButton.hidden = false;
    }

    update();
  };

  function update() {
    myMixismsButton.hidden = !auth.isAuthenticated();
    myMixismsButton.href = `/authors/${auth.getName()}/submissions`;
    newNewsHeaderIcon.hidden = !news.hasNewNews();
    newNewsMenuIcon.hidden = !news.hasNewNews();
  }

  function onClickAuthorMenu(event) {
    event.stopPropagation();
    authorMenu.hidden = !authorMenu.hidden;
  }

  async function onClickInstallApp(event) {
    event.stopPropagation();
    install.installApp();
  }

  return self;
};

export { HeaderView };
