'use strict';

import { EventEmitter } from './event';

const Auth = function () {
  const self = this;

  self.events = new EventEmitter(['namechange', 'signaturechange']);

  self.isAuthenticated = function () {
    return !!self.getSignature();
  };

  self.getName = function () {
    return localStorage.getItem('name');
  };

  self.setName = function(name) {
    localStorage.setItem('name', name);
    self.events.dispatchEvent('namechange', name);
  };

  self.getSignature = function () {
    return localStorage.getItem('signature');
  };

  self.setSignature = function(signature) {
    localStorage.setItem('signature', signature);
    self.events.dispatchEvent('signaturechange', signature);
  };

  self.clear = function() {
    localStorage.removeItem('name');
    localStorage.removeItem('signature');
    self.events.dispatchEvent('namechange', null);
    self.events.dispatchEvent('signaturechange', null);
  };

  return self;
};

export { Auth };
