'use strict';

import { Api } from './api';
import { Auth } from './auth';
import { Errors } from './errors';
import { Install } from './install';
import { News } from './news';
import { Router } from './router';

import { AuthorSubmissionsView } from './views/author-submissions';
import { AuthorView } from './views/author';
import { BannerView } from './views/banner';
import { ComposeBoardView } from './views/compose-board';
import { ComposeView } from './views/compose';
import { Error404View } from './views/error404';
import { HeaderView } from './views/header';
import { HomeView } from './views/home';
import { NewsView } from './views/news';
import { SettingsView } from './views/settings';
import { SubmissionListView } from './views/submission-list';
import { SubmissionsView } from './views/submissions';
import { SubmissionView } from './views/submission';
import { WinnersView } from './views/winners';

const headerRoot = document.getElementById('header');
const bannerRoot = document.getElementById('banner');
const mainRoot = document.getElementById('main');

const errors = new Errors();
const auth = new Auth();
const router = new Router();
const api = new Api({ auth, router });
const install = new Install();
const news = new News();

const bannerView = new BannerView({ router, errors });
const headerView = new HeaderView({ auth, install, news });

const composeBoardView = new ComposeBoardView();
const submissionListView = new SubmissionListView({ router, auth, api, bannerView });

const error404View = new Error404View();
const homeView = new HomeView({ install });
const settingsView = new SettingsView({ router, auth, api, bannerView });
const newsView = new NewsView({ news });
const composeView = new ComposeView({ router, api, auth, bannerView, composeBoardView });
const submissionView = new SubmissionView({ api, auth, bannerView, router });
const authorView = new AuthorView({ api, bannerView });
const authorSubmissionsView = new AuthorSubmissionsView({ submissionListView });
const submissionsView = new SubmissionsView({ submissionListView });
const winnersView = new WinnersView({ router, api, bannerView });

router.init({
  sections: {
    header: {
      root: headerRoot,
      view: headerView
    },
    banner: {
      root: bannerRoot,
      view: bannerView
    },
    main: {
      root: mainRoot,
      view: {}
    }
  },
  routes: {
    '/': { main: homeView },
    '/authors/:authorName': { main: authorView },
    '/authors/:authorName/submissions': { main: authorSubmissionsView },
    '/compose': { main: composeView },
    '/news': { main: newsView },
    '/settings': { main: settingsView },
    '/submissions': { main: submissionsView },
    '/submissions/:submissionId': { main: submissionView },
    '/winners': { main: winnersView },
    '/winners/:date': { main: winnersView }
  },
  noRoute: { main: error404View }
}); 
