'use strict';

import { EventEmitter } from './event';

const Install = function () {
  const self = this;

  let installAppPrompt;

  window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

  self.events = new EventEmitter(['readytoinstall']);

  self.canInstallApp = function () {
    return !!installAppPrompt;
  };

  self.installApp = async function () {
    if (self.canInstallApp()) {
      installAppPrompt.prompt();

      try {
        await installAppPrompt.userChoice;
      } catch (error) {
        console.error(error);
      }

      installAppPrompt = null;
    }
  };

  function onBeforeInstallPrompt(event) {
    event.preventDefault();
    installAppPrompt = event;
    self.events.dispatchEvent('readytoinstall');
  }

  return self;
};

export { Install };
