'use strict';

const AuthorView = function ({ api, bannerView }) {
  const self = this;

  let authorName;
  let submissionsCount;
  let winnerCounts;

  self.preinit = function (params) {
    authorName = params.authorName;
  };

  self.render = function () {
    return `
    <div id="author-info">
      <label>Pen Name</label>
      <div class="author-name">
        ${authorName}
      </div>
      <label>Mixisms Composed</label>
      <div id="author-submissions-count">
      </div>
      <div id="author-winner-counts">
      </div>
      <a
        class="button button-primary button-wide"
        aria-label="View Mixisms"
        href="/authors/${authorName}/submissions"
      >
        View Mixisms
      </a>
    </div>
    `;
  };

  self.init = async function () {
    submissionsCount = document.getElementById('author-submissions-count');
    winnerCounts = document.getElementById('author-winner-counts');

    try {
      const author = await api.request({
        method: 'GET',
        path: `/api/v1/authors/${authorName}`
      });
      if (!author) {
        throw new Error('Failed to load author');
      }

      submissionsCount.textContent = author.submissions_count;

      for (const winnerCount of author.winner_counts) {
        if (winnerCount.rank < 1) {
          continue;
        }

        const winner = document.createElement('div');
        winner.classList.add('winner');

        const winnerCountText = document.createElement('div');
        winnerCountText.classList.add('winner-count');
        winnerCountText.textContent = winnerCount.count;

        const winnerRank = document.createElement('div');
        winnerRank.classList.add('winner-rank');

        const ribbon = document.createElement('div');
        ribbon.classList.add('ribbon', 'ribbon-large');
        ribbon.textContent = `#${winnerCount.rank}`;
        winnerRank.append(ribbon);

        winner.append(winnerCountText);
        winner.append(winnerRank);

        winnerCounts.append(winner);
      }
    } catch (error) {
      console.error(error);
      bannerView.addMessagesForError(error);
    }
  };

  return self;
};

export { AuthorView };
