'use strict';

const ComposeBoardView = function () {
  const self = this;

  const numSlots = 45;
  const numSlotsInRow = 9;
  const punctuation = ['.', ',', '?', '!', '\''];

  let composeMessage;
  let composeBoard;
  let dragLetter;
  let sourceSlot;
  let sourceLetter;
  let touchId;

  self.render = function () {
    return `
    <div id="compose-message-container">
      <span id="compose-message"></span>
    </div>
    <div id="compose-board">
    </div>
    <div id="compose-drag-letter" hidden>
    </div>
    `;
  };

  self.init = async function () {
    composeMessage = document.getElementById('compose-message');
    composeBoard = document.getElementById('compose-board');
    dragLetter = document.getElementById('compose-drag-letter');
    sourceSlot = null;
    sourceLetter = null;

    touchId = null;

    createBoard();
    dragLetter.appendChild(createLetterTile('a'));
  };

  self.clearSavedState = function () {
    localStorage.setItem('letters', '');
    localStorage.setItem('state', '');
  };

  self.setLetters = function (letters) {
    const slots = document.getElementsByClassName('compose-slot');

    for (const slot of slots) {
      const letters = slot.getElementsByTagName('svg');
      if (letters.length > 0) {
        slot.removeChild(letters[0]);
      }
    }

    const indices = [];
    for (let i = 0; i < slots.length; i++) {
      indices[i] = i;
    }

    const savedLetters = localStorage.getItem('letters');
    const savedState = localStorage.getItem('state');
    if (savedLetters && savedState && letters === savedLetters) {
      for (let i = 0; i < savedState.length; i++) {
        if (savedState[i] !== ' ') {
          const letterTile = createLetterTile(savedState[i]);
          slots[indices[i]].appendChild(letterTile);
        }
      }
    } else {
      for (let i = indices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = indices[i];
        indices[i] = indices[j];
        indices[j] = temp;
      }

      for (let i = 0; i < letters.length; i++) {
        const letterTile = createLetterTile(letters[i]);
        slots[indices[i]].appendChild(letterTile);
      }
    }

    localStorage.setItem('letters', letters);
    localStorage.setItem('state', self.getState());

    composeMessage.textContent = self.getMessage();
  };

  self.getMessage = function () {
    let message = '';
    const slots = document.getElementsByClassName('compose-slot');
    for (let i = 0; i < slots.length; i++) {
      const letter = slots[i].getElementsByTagName('svg')[0];
      if (letter) {
        const text = letter.getElementsByTagName('text')[0];
        message += text.textContent.toLowerCase();

        if (punctuation.includes(text.textContent) && text.textContent !== '\'') {
          message += ' ';
        }
      } else {
        message += ' ';
      }

      if ((i + 1) % numSlotsInRow === 0) {
        message += ' ';
      }
    }

    message = message.replace(/([.,?!']) ([.,?!'])/g, '$1$2');
    message = message.replace(/([.,?!']) ([.,?!'])/g, '$1$2');
    message = message.replace(/([a-z][.]) ([a-z][.])/g, '$1$2');
    message = message.replace(/([a-z][.]) ([a-z][.])/g, '$1$2');
    message = message.replace(/\s+/g, ' ').trim();

    return message;
  };

  self.destroy = function () {
    window.removeEventListener('mousemove', onGlobalMouseMove);
    window.removeEventListener('mouseup', onGlobalMouseUp);
  };

  self.getState = function () {
    let state = '';
    const slots = document.getElementsByClassName('compose-slot');
    for (let i = 0; i < slots.length; i++) {
      const letter = slots[i].getElementsByTagName('svg')[0];
      if (letter) {
        const text = letter.getElementsByTagName('text')[0];
        state += text.textContent.toLowerCase();
      } else {
        state += ' ';
      }
    }

    return state;
  };

  function createBoard() {
    for (let i = 0; i < numSlots; i++) {
      const slotContainer = document.createElement('div');
      slotContainer.classList.add('compose-slot-container');

      const slot = document.createElement('div');
      slot.classList.add('compose-slot');

      const emptySlot = document.createElement('div');
      emptySlot.classList.add('compose-empty-slot');

      slot.addEventListener('mousedown', onSlotMouseDown);
      slot.addEventListener('mouseup', onSlotMouseUp);
      slot.addEventListener('touchstart', onSlotTouchStart);
      slot.addEventListener('touchend', onSlotTouchEnd);

      slot.appendChild(emptySlot);
      slotContainer.appendChild(slot);
      composeBoard.appendChild(slotContainer);
    }

    window.addEventListener('mousemove', onGlobalMouseMove);
    window.addEventListener('mouseup', onGlobalMouseUp);
    composeBoard.addEventListener('touchmove', onGlobalTouchMove, { passive: false });
    composeBoard.addEventListener('touchend', onGlobalTouchEnd);
  }

  function createLetterTile(letter) {
    const letterSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    letterSvg.setAttribute('width', '100%');
    letterSvg.setAttribute('height', '100%');
    letterSvg.setAttribute('viewBox', '0 0 100 100');
    letterSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');

    const letterBackground = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    letterBackground.setAttribute('width', '100%');
    letterBackground.setAttribute('height', '100%');
    letterBackground.setAttribute('fill', '#7b7193');
    letterBackground.setAttribute('rx', '15');

    const letterText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    letterText.setAttribute('font-size', '4em');
    letterText.setAttribute('fill', '#ffffff');
    letterText.setAttribute('x', '50%');
    letterText.setAttribute('y', '55%');
    letterText.setAttribute('text-anchor', 'middle');
    letterText.setAttribute('dominant-baseline', 'middle');
    letterText.textContent = letter.toUpperCase();

    letterSvg.appendChild(letterBackground);
    letterSvg.appendChild(letterText);

    return letterSvg;
  }

  function getTouch(touchList, touchId) {
    for (let i = 0; i < touchList.length; i++) {
      if (touchList[i].identifier === touchId) {
        return touchList[i];
      }
    }

    return null;
  }

  function getPageX(x) {
    return (x - document.body.scrollLeft - document.documentElement.scrollLeft);
  }

  function getPageY(y) {
    return (y - document.body.scrollTop - document.documentElement.scrollTop);
  }

  function setDragLetterToPosition(x, y) {
    dragLetter.style.left = (getPageX(x) - (dragLetter.offsetWidth / 2)) + 'px';
    dragLetter.style.top = (getPageY(y) - (dragLetter.offsetHeight / 2)) + 'px';
  }

  function onSlotMouseDown(event) {
    if ((event.which && event.which === 3) || (event.button && event.button === 2)) {
      return;
    }

    sourceSlot = event.target.closest('.compose-slot');
    const sourceLetters = sourceSlot.getElementsByTagName('svg');
    if (sourceLetters.length === 0) {
      return;
    }
    sourceLetter = sourceLetters[0];

    sourceLetter.style.visibility = 'hidden';

    dragLetter.hidden = false;
    dragLetter.getElementsByTagName('text')[0].textContent = sourceLetter.getElementsByTagName('text')[0].textContent;
    setDragLetterToPosition(event.pageX, event.pageY);
  }

  function onSlotTouchStart(event) {
    if (!event.cancelable) {
      return;
    }
    event.preventDefault();

    if (event.touches.length > 1) {
      return;
    }

    const touch = event.touches[0];
    touchId = touch.identifier;

    sourceSlot = event.target.closest('.compose-slot');
    const sourceLetters = sourceSlot.getElementsByTagName('svg');
    if (sourceLetters.length === 0) {
      return;
    }
    sourceLetter = sourceLetters[0];

    sourceLetter.style.visibility = 'hidden';

    dragLetter.hidden = false;
    dragLetter.getElementsByTagName('text')[0].textContent = sourceLetter.getElementsByTagName('text')[0].textContent;
    setDragLetterToPosition(touch.pageX, touch.pageY);
  }

  function onSlotMouseUp(event) {
    const targetSlot = event.target.closest('.compose-slot');
    if (!targetSlot) {
      return;
    }

    if (targetSlot === sourceSlot) {
      // Create new punctuation
      if (!sourceLetter) {
        sourceLetter = createLetterTile(punctuation[0]);
        sourceSlot.appendChild(sourceLetter);
        return;
      }

      // Cycle through punctuation
      const sourceText = sourceLetter.getElementsByTagName('text')[0];
      if (punctuation.includes(sourceText.textContent)) {
        if (sourceText.textContent === punctuation[punctuation.length - 1]) {
          sourceSlot.removeChild(sourceLetter);
          return;
        }

        sourceText.textContent = punctuation[(punctuation.indexOf(sourceText.textContent) + 1) % punctuation.length];
      }
    }

    if (!sourceLetter) {
      return;
    }

    if (targetSlot.getElementsByTagName('svg').length > 0) {
      return;
    }

    sourceSlot.removeChild(sourceLetter);
    targetSlot.appendChild(sourceLetter);
  }

  function onSlotTouchEnd(event) {
    if (!event.cancelable) {
      return;
    }
    event.preventDefault();

    const touch = getTouch(event.changedTouches, touchId);
    if (!touch) {
      return;
    }

    const endTarget = document.elementFromPoint(getPageX(touch.pageX), getPageY(touch.pageY));
    if (!endTarget) {
      return;
    }
    const targetSlot = endTarget.closest('.compose-slot');
    if (!targetSlot) {
      // Remove punctuation letters released off the board
      if (sourceLetter && punctuation.includes(sourceLetter.getElementsByTagName('text')[0].textContent)) {
        sourceSlot.removeChild(sourceLetter);
      }
      return;
    }

    if (targetSlot === sourceSlot) {
      // Create new punctuation
      if (!sourceLetter) {
        sourceLetter = createLetterTile(punctuation[0]);
        sourceSlot.appendChild(sourceLetter);
        return;
      }

      // Cycle through punctuation
      const sourceText = sourceLetter.getElementsByTagName('text')[0];
      if (punctuation.includes(sourceText.textContent)) {
        if (sourceText.textContent === punctuation[punctuation.length - 1]) {
          sourceSlot.removeChild(sourceLetter);
          return;
        }

        sourceText.textContent = punctuation[(punctuation.indexOf(sourceText.textContent) + 1) % punctuation.length];
      }
    }

    if (!sourceLetter) {
      return;
    }

    if (targetSlot.getElementsByTagName('svg').length > 0) {
      return;
    }

    sourceSlot.removeChild(sourceLetter);
    targetSlot.appendChild(sourceLetter);
  }

  function onGlobalMouseMove(event) {
    if (!event.cancelable) {
      return;
    }
    event.preventDefault();

    if (!sourceLetter) {
      return;
    }

    setDragLetterToPosition(event.pageX, event.pageY);
  }

  function onGlobalTouchMove(event) {
    if (!sourceLetter) {
      return;
    }

    if (!event.cancelable) {
      return;
    }
    event.preventDefault();

    const touch = getTouch(event.changedTouches, touchId);
    if (!touch) {
      return;
    }

    setDragLetterToPosition(touch.pageX, touch.pageY);
  }

  function onGlobalMouseUp(event) {
    // Remove punctuation letters released off the board
    const targetBoard = event.target.closest('#compose-board');
    if (!targetBoard && sourceLetter && punctuation.includes(sourceLetter.getElementsByTagName('text')[0].textContent)) {
      sourceSlot.removeChild(sourceLetter);
    }

    localStorage.setItem('state', self.getState());

    composeMessage.textContent = self.getMessage();

    dragLetter.hidden = true;

    if (sourceLetter) {
      sourceLetter.style.visibility = 'visible';
    }

    sourceLetter = null;
    sourceSlot = null;
  }

  function onGlobalTouchEnd(event) {
    if (!event.cancelable) {
      return;
    }
    event.preventDefault();

    localStorage.setItem('state', self.getState());

    composeMessage.textContent = self.getMessage();

    const touch = getTouch(event.changedTouches, touchId);
    if (!touch) {
      return;
    }
    touchId = null;

    dragLetter.hidden = true;

    if (sourceLetter) {
      sourceLetter.style.visibility = 'visible';
    }

    sourceLetter = null;
    sourceSlot = null;
  }

  return self;
};

export { ComposeBoardView };
