'use strict';

const HomeView = function ({ install }) {
  const self = this;

  const messages = [
    'thieve all my leftover income',
    'keep in some child kazoo laws',
    'my effort to examine was lame',
    'his reaction takes the goats',
    'relations of merit are stuck',
    'a sister of many reeled a fish',
    'can oven storage lift a photo?',
    'the courage pie has zany pits',
    'three tea baskets are touchy',
    'bird hair never pats one\'s poo',
    'mazes in motion can look quick',
    'two farmers can\'t make an area',
    'an exact error in person soup',
    'truth states coffee is a love',
    'my wealth river leaves me too',
    'the village cat man whines \'oo!\'',
    'midnight is a loud time for me',
    'lady of hats cleans out a rake',
    'use the blow engine when late',
    'lawful boys aim at foot nails'
  ];

  let installAppButton;
  let quote1;
  let quote2;
  let messageIndex;
  let newTop;
  let newLeft;
  let quoteTimeout;

  install.events.addEventListener('readytoinstall', () => {
    if (installAppButton) {
      installAppButton.hidden = false;
    }
  });

  self.render = function () {
    return `
    <div class="home-banner">
      <h1><span class="logo-m-large">M</span>ixisms</h1>
      <div id="home-quote-1" class="home-quote">
      </div>
      <div id="home-quote-2" class="home-quote">
      </div>
    </div>

    <div class="home-description">
      <h1>Find meaning in the mess!</h1>

      <div class="home-buttons">
        <a class="button button-primary button-wide" href="/compose">Get Started</a>
        <button
          id="home-install-app-button"
          type="button"
          class="button button-primary button-wide"
          hidden
        >
          Install the App
        </button>
      </div>

      <h2>How does it work?</h2>
      <p>
        Each day, a new set of letters is randomly selected. For the entirety of that day,
        you and others can compose Mixisms by rearranging all of the letters to say something
        meaningful.
      </p>
      <p>
        For example, if the letters for a given day were:
      </p>
      <p class="example-letters">
        A B C E E F G H K O R R S T U
      </p>
      <p>
        You could submit <em>"feather bugs rock"</em> or <em>"burst a frog cheek"</em>
        or anything else you can come up with using those letters.
      </p>
      <p>
        Once you've come up with your masterpiece, you can submit it to be
        reviewed and enjoyed by the community.
      </p>
      <p>
        After the day is complete, you and others have a week to vote on which
        Mixisms you feel offer the most wisdom, have the most sage advice, or just make
        you laugh the hardest.
      </p>
      <p>
        Once the week is up, votes are locked in and winners are chosen
        for the day on which those Mixisms were created.
      </p>

      <h2>Can I use punctuation?</h2>
      <p>
        You are allowed to add as much punctuation as you'd like to your Mixism
        (within the limits of the Composition Board's space). To do so, just tap
        an empty space or an existing punctuation mark to cycle through the available
        symbols.
      </p>
      <p>
        If you decide you no longer want to use the punctuation mark, you can either
        continue tapping it until the spot is blank again, or just drag it off the board.
      </p>

      <h2>Are there any prizes?</h2>
      <p>
        Just the pure unadulterated satisfaction of being a Mixism master!
      </p>

      <h2>Can I compose Mixisms under the same Pen Name on different devices?</h2>
      <p>
        Yes you can! Simply go to Settings on both devices, and copy your signature
        from one device to the other to link the new device to your Pen Name.
      </p>

      <h2>Who are you?</h2>
      <p>
        My name is John Langewisch. I'm a full-time software developer, hobbyist game developer,
        musician, and a guy who really enjoys word games. You can check out more of my projects
        on my <a href="https://jahndis.com" target="_blank" rel="noreferrer" class="link">website</a>.
      </p>
      <p>
        Please keep in mind that this application is a side project for me right now,
        and while I intend to keep things working and support its users, I may not
        always have the capacity to do so.
      </p>
      <p>
        If you'd like to contact me, feel free to shoot me an <a href="mailto:jlangewi@gmail.com" target="_blank" rel="noreferrer" class="link">email</a>
        or give me a follow on <a href="https://twitter.com/jahndis" target="_blank" rel="noreferrer" class="link">Twitter</a>!
      </p>
   </div>
    `;
  };

  self.init = function () {
    installAppButton = document.getElementById('home-install-app-button');
    installAppButton.addEventListener('click', onClickInstallApp);
    quote1 = document.getElementById('home-quote-1');
    quote2 = document.getElementById('home-quote-2');

    if (install.canInstallApp()) {
      installAppButton.hidden = false;
    }

    messageIndex = Math.floor(Math.random() * messages.length);

    quoteTimeout = setTimeout(showQuote, 1000);
  };

  self.destroy = function () {
    clearTimeout(quoteTimeout);
  };

  function showQuote() {
    newLeft = `${5 + Math.floor(Math.random() * (window.innerWidth - 290))}px`;

    if (quote1.style.opacity === '0') {
      newTop = `${20 + Math.floor(Math.random() * 50)}px`;
      quote1.textContent = messages[messageIndex];
      quote1.style.opacity = '1';
      quote1.style.top = newTop;
      quote1.style.left = newLeft;
      quote1.style.paddingTop = '0px';
      quote2.style.opacity = '0';
      quote2.style.paddingTop = '20px';
    } else {
      newTop = `${200 + Math.floor(Math.random() * 50)}px`;
      quote1.style.opacity = '0';
      quote1.style.paddingTop = '20px';
      quote2.textContent = messages[messageIndex];
      quote2.style.opacity = '1';
      quote2.style.top = newTop;
      quote2.style.left = newLeft;
      quote2.style.paddingTop = '0px';
    }
    messageIndex += 1;
    if (messageIndex >= messages.length) {
      messageIndex = 0;
    }

    quoteTimeout = setTimeout(showQuote, 5000);
  }

  async function onClickInstallApp() {
    install.installApp();
  }

  return self;
};

export { HomeView };
