'use strict';

const Api = function ({ auth, router }) {
  const self = this;

  self.request = async function ({ method, path, headers, params, data }) {
    const defaultHeaders = {
      'Content-Type': 'application/json',
    };
    const name = auth.getName();
    const signature = auth.getSignature();
    if (signature) {
      defaultHeaders['Authorization'] = `Bearer ${name}:${signature}`;
    }
    headers = {
      ...defaultHeaders,
      ...(headers || {})
    };

    const url = new URL(path.startsWith('/') ? window.location.origin + path : path);
    Object.entries(params || {}).forEach(([key, value]) => url.searchParams.append(key, value));

    const body = JSON.stringify(data);

    let response;
    let json;
    try {
      response = await fetch(url, { method, headers, body });
      json = await response.json();
    } catch (error) {
      console.error(error);
      throw new Error('Request failed');
    }

    if (!response.ok) {
      if (response.status === 401) {
        router.goto('/');
      }

      if (response.status === 403) {
        router.goto('/');
      }

      if (response.status === 404) {
        router.goto('/');
      }

      if (json.errors) {
        throw json;
      } else {
        throw new Error('Request failed');
      }
    }

    return json;
  };

  return self;
};

export { Api };
