'use strict';

const AuthorSubmissionsView = function ({ submissionListView }) {
  const self = this;

  let authorName;

  self.views = {
    submissionList: submissionListView
  };

  self.preinit = function (params) {
    authorName = params.authorName;
    submissionListView.setSource(`/api/v1/authors/${authorName}/submissions`);
  };

  self.render = function () {
    return `
    ${submissionListView.render()}
    `;
  };

  self.init = function () {
  };

  return self;
};

export { AuthorSubmissionsView };
