'use strict';

const Error404View = function () {
  const self = this;

  self.render = function () {
    return `
    <h1>404</h1>
    `;
  };

  return self;
};

export { Error404View };
